import { UrlUtil } from '@shared/url-util';

const BASE_URL = window.environment.baseUrl;

export class Urls {
    public static readonly aboutPage = 'about';
    // todo: where should the native app urls come from? how are they updated when new apps are published? will different apps be published for >CW23?
    public static readonly androidNativeStore = 'https://play.google.com/store/apps/details?id=com.cityworks.android12';
    public static readonly appSelectPage = BASE_URL.toString();
    public static readonly iosNativeStore = 'https://apps.apple.com/us/app/cityworks-12/id1670745802';
    public static readonly logInPage = 'login';
    public static readonly logoutPage = UrlUtil.makeUrl(BASE_URL, 'logout', undefined);
    public static readonly passwordResetPage = 'password-reset';
    public static readonly pluginManagementPage = 'admin/plugin-management';
    public static readonly privacyNotice = 'https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice';

    public static makeAppSelectionUrl(): URL {
        return UrlUtil.makeUrl(BASE_URL, '', undefined);
    }

    public static makePluginUrl(route: string, pluginRelativePath: string): URL {
        return UrlUtil.makeUrl(BASE_URL, UrlUtil.joinUrlParts(`apps/${route}`, pluginRelativePath), undefined);
    }

    /**
     * Set either option.lastApp (to open last app) or option.uid
     * (to open the app with the UID)
     * but do not set both.
     */
    public static makeSwitchAppUrl(option: { lastApp?: boolean; uid?: string }): URL {
        const params: Record<string, string> = {};
        if (option.lastApp) {
            params.last = 'true';
        }
        if (option.uid) {
            params.uid = option.uid;
        }
        return UrlUtil.makeUrl(BASE_URL, 'switch-app', params);
    }
}
