import { Localized } from '@fluent/react';
import { If } from '@shared/ui/if.component';
import { CWUser, Oauth2LoginLink } from 'cw-isdk/core';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { Urls } from '../../urls';
import { Input } from './input.component';
import css from './log-in-form.component.less';
import { useLogIn } from './use-log-in.hook';
import { CwIcon } from '@shared/ui/cw-icon.component';

export function LogInForm(props: LogInFormProps): React.ReactElement {

    function execLogIn(): void {
        logIn(username, password);
    }

    function generateOauth2Links(oauth2: Oauth2LoginLink[]): React.ReactNode {
        if (!oauth2?.length) { return; }

        return (
            <div key='oauth2Options' className={css.oauth2Options}>
                <span className={`${css.textSecondary} ${hideDefaultLoginClass()}`}><Localized id='login-oauth-options'>More ways to login:</Localized></span>
                <ul key='oauth2OptionsList'>
                    {oauth2.map(itm =>
                        <li key={`client${itm.ClientConfigurationId}_li`}>
                            <Button variant='light' key={`client${itm.ClientConfigurationId}_a`} className={css.oauth2Name} href={itm.LoginUri}>
                                <img key={`client${itm.ClientConfigurationId}_img`} className={css.oauth2Logo} src={itm.IconUri} alt={itm.SiteName} />
                                {itm.DisplayName ?? itm.SiteName}
                            </Button>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    function hideDefaultLoginClass() {
        // do we need a way to toggle default login instead of just hiding it?
        return (props?.oauth2?.length ?? 0) > 0 && props?.hideDefaultLogin ? css.hidden : '';
    }

    const { errorMessage: logInErrorMessage, loading: loggingIn, logIn } = useLogIn(user => props.loggedIn(user));
    const [password, setPassword] = useState<string>('');
    const [username, setUsername] = useState<string>('');

    return (
        <Card className='shadow-sm'>
            <Card.Body className={css.cardBody}>
                <h1><Localized id='login-heading'>Log In</Localized></h1>
                <div className={css.formWrapper}>
                    {
                        logInErrorMessage != null
                            ? <Alert variant='danger'>{logInErrorMessage}</Alert>
                            : null
                    }
                    <form className={hideDefaultLoginClass()}>
                        <Localized id='login-input-username' attrs={{ label: true }}>
                            <Input
                                className={css.credentialInput}
                                autocomplete='username'
                                label='Username'
                                type='text'
                                cwId='txtLoginUserName'
                                required
                                value={username}
                                onChange={value => setUsername(value)}
                                onEnter={() => execLogIn()} />
                        </Localized>
                        <Localized id='login-input-password' attrs={{ label: true }}>
                            <Input
                                className='input-with-icon-right'
                                autocomplete='current-password'
                                label='Password'
                                type='password'
                                cwId='txtLoginPassword'
                                required
                                value={password}
                                onChange={value => setPassword(value)}
                                onEnter={() => execLogIn()} />
                        </Localized>
                        <a className={css.forgotPassword} href={Urls.passwordResetPage}><Localized id='login-link-reset-password'>Forgot Password</Localized></a>
                        <div className={css.loginButtonGroup}>
                            <Button variant='primary' onClick={() => execLogIn()} data-cw-id='btnLogin'>
                                <If
                                    if={loggingIn}
                                    then={() => (
                                        <Spinner className='text-white' animation='border' size='sm' role='status'>
                                            <span className='visually-hidden'><Localized id='login-button-login-loading'>Logging in…</Localized></span>
                                        </Spinner>
                                    )} />
                                {loggingIn ? ' ' : <Localized id='login-button-login'>Login</Localized>}
                            </Button>
                        </div>
                    </form>
                    <div key='oauth2-div'>{generateOauth2Links(props.oauth2)}</div>
                </div>
                <div className={css.gridFooterRow}>
                    <div className={`form-labels card-caption ${css.footerContentWrapper}`}>
                        <If
                            if={window.environment.whiteLabel.showPoweredBy}
                            then={() => (
                                <div className={css.poweredBy}>
                                    <Localized id='login-powered-by'>
                                        Powered by
                                    </Localized>
                                    <CwIcon aria-label='Cityworks' aria-hidden='false'>cityworks-logo</CwIcon>
                                </div>
                            )} />
                        <div>
                            <div>
                                {window.environment.appInfo.apiVersionDescription}
                            </div>
                        </div>
                        <div>
                            <Localized id='login-copyright' vars={{year: new Date().getFullYear().toString()}}>
                                {'© 2009–{ $year } Azteca Systems, LLC'}
                            </Localized>
                            <div>
                                <Localized id='login-terms-privacy'>
                                    <a className={css.privacyNotice} href={Urls.privacyNotice} target='_blank'
                                       rel='noopener noreferrer'>Privacy Notice</a>
                                </Localized>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export interface LogInFormProps {
    loggedIn: (user: CWUser) => void;
    oauth2: Oauth2LoginLink[];
    hideDefaultLogin: boolean;
}
